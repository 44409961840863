function unsetCookies() {
	window['ga-disable-SMW2BK33PF'] = true;
	document.cookie = '_ga_SMW2BK33PF=0; Expires: Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = '_ga=0; Expires: Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
	document.cookie = '_gid=0; Expires: Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
}

function pxtorem(int) {
	return (int / 16);
}

function setTransitionEnd() {
	var transitions = {
		'transition' : 'transitionend',
		'OTransition' : 'oTransitionEnd',
		'MozTransition' : 'transitionend',
		'WebkitTransition' : 'webkitTransitionEnd'
	};

	let d = document.body.style;
	for (let transition in transitions) {
		if (d[transition] != undefined) {
			window.transend = transitions[transition];
			break;
		}
	}
}

var triggerPoint = .8;

$(function() {
	setTransitionEnd();
	let ww = $(window).innerWidth();
	if (ww <= 768) {
		window.personaggi = new Swiper(".personaggi.swiper-container", {
			navigation: {
				prevEl: '.swiper-navigation .swiper-prev',
				nextEl: '.swiper-navigation .swiper-next'
			},
			on: {
				init: function() { $('.personaggi-container').addClass('on'); }
			}
		});
	}
	else {
		$('.personaggi-container').addClass('on');
	}

	$('.fade-up').on('ScrollioMatch', function() {
		$(this).addClass('on');
	}).on('ScrollioUnmatch', function() {
		$(this).removeClass('on');
	}).Scrollio({
		action: 'toggleClass',
		class: 'on',
		trigger: {
			multiplier: triggerPoint
		}
	});

	$(window).on('resize', function() {
		let ww = $(window).innerWidth();
		if (ww > 768) {
			if (typeof(window.personaggi) === 'object') {
				window.personaggi.destroy();
			}
		}
		else {
			window.personaggi = new Swiper(".personaggi.swiper-container", {
				navigation: {
					prevEl: '.swiper-navigation .swiper-prev',
					nextEl: '.swiper-navigation .swiper-next'
				},
				on: {
					init: function() { $('.personaggi-container').addClass('on'); }
				}
			});
		}
	});

	function concertina($elm) {
		let dtId = $elm.attr('id'),
			ddId = dtId.replace('d','r'),
			$dd = $('#' + ddId),
			$content = $('#'+ ddId +' .content-wrapper');

		if (!$dd.attr('style')) {
			$elm.addClass('active');
			$dd.addClass('active');
			$dd.one(window.transend, function() {
				$(window).trigger('viewchanged');
			});
			$dd.css({'max-height': pxtorem($content.outerHeight()) +'rem'});
		}
		else {
			$elm.removeClass('active');
			$dd.one(window.transend, function() {
				$(window).trigger('viewchanged');
			});
			$dd.removeClass('active');
			$dd.removeAttr('style');
		}
	}

	$('dt').each(function() {
		$(this).on('click', function(e) {
			e.preventDefault();
			concertina($(this));
		});
	});

	$('a[rel="modal"]').each(function() {
		$(this).click(function(e) {
			e.preventDefault();
			Modal.open($(this), $(this).prop('href'));
		});
	});

	if ($('.img-gallery').length) {
		var img_gallery = new Swiper('.img-gallery', {
			simulateTouch: false,
			slidesPerView: 1,
			spaceBetween: 16,
			pagination: {
				el: '.swiper-pagination',
				clickable: 'true'
			},
			breakpoints: {
				640: {
					slidesPerView: 2
				},
				1024: {
					slidesPerView: 3
				}
			}
		});
	}

	if (!localStorage.getItem('cookie-preference')) {
		$('.gdpr-bar').addClass('on');
	}
	else if (localStorage.getItem('cookie-preference') == 0) {
		unsetCookies();
	}
	else {
		$('<script async src=https://www.googletagmanager.com/gtag/js?id=G-SMW2BK33PF></script><script>window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'G-SMW2BK33PF\');</script>').insertBefore($('script').first());
	}

	$('.gdpr-bar .gdpr-accept').on('click', function(e) {
		localStorage.setItem('cookie-preference', 1);
		$('.gdpr-bar').removeClass('on');
	});

	$('.gdpr-bar .gdpr-refuse').on('click', function(e) {
		localStorage.setItem('cookie-preference', 0);
		unsetCookies();
		$('.gdpr-bar').removeClass('on');
	});
	
	if ($('.social-wall').length) {
		new instaSlide('.instagram-posts li:nth-of-type(1) .instagram-switcher', { timing: 10000, paused: false, delay: 100, transition: 1000});
		new instaSlide('.instagram-posts li:nth-of-type(2) .instagram-switcher', { timing: 10000, paused: false, delay: 350, transition: 1000});
		new instaSlide('.instagram-posts li:nth-of-type(3) .instagram-switcher', { timing: 10000, paused: false, delay: 750, transition: 1000});
	}
});